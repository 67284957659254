.p-dialog {
  border-radius: 3px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #c8c8c8;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #c8c8c8;
  background: #f4f4f4;
  color: #333333;
  padding: 1rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #a6a6a6;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #007ad9;
  border-color: transparent;
  background: transparent;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #8dcdff;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #333333;
  padding: 1rem;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid #c8c8c8;
  background: #ffffff;
  color: #333333;
  padding: 0.571rem 1rem;
  text-align: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
